import { useState } from "react";
import "./style.css";

export default function GetUserComponent({ setUser, setLoggedIn }) {
  const [userInput, setUserInput] = useState("");

  const handleLogin = (userInput) => {
    setUser(userInput);
    setLoggedIn(true);
  };

  return (
    <>
      <form className="flex">
        <input
          placeholder="Seu nome"
          type="text"
          value={userInput}
          onChange={(event) => setUserInput(event.target.value)}
        />
        <button onClick={() => handleLogin(userInput)}>Acessar</button>
      </form>
    </>
  );
}
