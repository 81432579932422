import { useState } from "react";
import GetUserComponent from "./components/GetUserComponent";
import WelcomePage from "./components/WelcomePage";
import "./App.css";

export default function App() {
  const [LoggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState("");

  return (
    <div className="App">
      <header className="App-header">
        {LoggedIn ? (
          <WelcomePage user={user} setLoggedIn={setLoggedIn} />
        ) : (
          <GetUserComponent setUser={setUser} setLoggedIn={setLoggedIn} />
        )}
      </header>
    </div>
  );
}
