export default function WelcomePage({ user, setLoggedIn }) {
  const handleLogout = (setLoggedIn) => {
    setLoggedIn(false);
  };

  return (
    <div>
      <h1>Olá, {user}</h1>
      <button onClick={() => handleLogout(setLoggedIn)}>Logout</button>
    </div>
  );
}
